import { FC, useState, useEffect } from 'react';
import { useAccount } from 'wagmi';
import axios from 'axios';
import { ToastContext } from 'context/ToastContextProvider';

import { createContext, useContext } from 'react';
import { ReactNode } from 'react';

import { useSignMessage } from 'wagmi'
import { Button } from 'components/primitives';

import jwt from 'jsonwebtoken';
import { signMessage } from 'wagmi/actions';

type AuthContextType = {
  token: string | null;
  login: (signature: string) => Promise<void>;
  logout: () => void;
};

type AuthProviderProps = {
  children: ReactNode;
};


const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const { address: accountAddress, isConnected } = useAccount();
  const [token, setToken] = useState<string | null>(null);
  const [showLoginOverlay, setShowLoginOverlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useContext(ToastContext);

  const login = async (signature: string) => {
    try {
      const response = await axios.post('/api/auth/login', { address: accountAddress, signature });
      setToken(response.data.token);
      localStorage.setItem('authToken', response.data.token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      setShowLoginOverlay(false);
    } catch (error) {

      addToast?.({
        title: 'An error occured',
        description: 'We were unable to fetch your token. Please try again',
      });
    }
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem('authToken');
  };

  function isTokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  async function doesTokenWork(token: string) {
    try {
    const user = await axios.get('/api/user', { headers: { Authorization: `Bearer ${token}` } });
    if (user.data.address.toLowerCase() === accountAddress?.toLowerCase()) {
      return true;
    } else {
      return false;
    }
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    if (isConnected && accountAddress) {
      const storedToken = localStorage.getItem('authToken');
      if (storedToken) {
        doesTokenWork(storedToken).then(workingToken => {
          if (isTokenExpired(storedToken) || !workingToken) {
            setShowLoginOverlay(true);
          } else {
            setToken(storedToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
            setShowLoginOverlay(false);
          }
        }).catch(error => {
          console.log('Error decoding token: ', error);
        });
      } else {
        setShowLoginOverlay(true);
      }
    }
  }, [isConnected, accountAddress]);

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {showLoginOverlay && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <p style={{color: '#fff', marginBottom: '20px'}}>You must sign a message to prove who you are</p>
          <Button disabled={isLoading} onClick={async () => {
            try {
              setIsLoading(true);
              const data = await axios.get('/api/auth/login?address=' + accountAddress);
              const signMessageData = await signMessage({
                message: data.data.message,
              });
              login(signMessageData);
            } catch (error) {
                addToast?.({
                    title: 'An error occured',
                    description: 'You didn\'t sign the message. Please try again',
                });
            }
            setIsLoading(false);
          }}>
            {!isLoading ? "Sign Message" : "Loading..."}
          </Button>
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};